<template>
    <div>
        <div class="container">
            <h1 class="page-title">{{ $t("title") }}</h1>
            <p v-html="$t('alert')"></p>
        </div>

        <div class="container">
            <div class="media-flex-wrap">
                <div class="media-flex">
                    <div class="media-flex-item copy">
                        <CompatibilityBlock
                            v-for="device in devices"
                            :category="device"
                            :devices="getDevices(device.toLowerCase())"
                            :key="device"
                        />
                    </div>
                </div>
            </div>
        </div>

        <hr />

        <div class="container">
            <div class="media-flex-wrap">
                <div class="media-flex">
                    <div class="media-flex-item">
                        <h2 class="section-title">
                            {{ $t("category.android") }}
                        </h2>
                        <p>{{ $t("comming_soon") }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue"
import { mapActions, mapGetters } from "vuex"
import CompatibilityBlock from "../components/CompatibilityBlock"

import ios from "../assets/compatibility/en/ios"
import android from "../assets/compatibility/en/android"
import others from "../assets/compatibility/en/others"
import iosJa from "../assets/compatibility/ja/ios"
import androidJa from "../assets/compatibility/ja/android"
import othersJa from "../assets/compatibility/ja/others"

export default {
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.title"),
            description: this.$i18n.t("meta.description"),
        })
    },

    i18n: {
        messages: {
            en: {
                meta: {
                    title: "eSIM Supported Devices",
                    description:
                        "A list of eSIM supported devices that are compatible with Soracom Mobile eSIM for mobile data",
                },
                title: "Compatibility",
                alert: "Your device must be unlocked and support eSIM.",
                category: {
                    ios: "iOS",
                    android: "Android",
                },
                comming_soon: "Coming Soon",
            },
            ja: {
                meta: {
                    title: "利用可能端末",
                    description:
                        "ソラコム モバイルのeSIMを使用できる動作保証端末の一覧です。必ず一覧に記載のある端末でご利用ください。",
                },
                title: "利用可能端末",
                alert:
                    "eSIMに対応しているSIMロックフリー端末（SIMフリー端末）、<br />あるいはSIMロック解除端末のみご利用いただけます。",
                category: {
                    ios: "iOS",
                    android: "Android",
                },
                comming_soon: "順次提供開始予定",
            },
        },
    },

    components: { CompatibilityBlock },
    data() {
        return {
            modalOpen: false,
            detailCoverage: null,
            devices: ["iOS"],
        }
    },
    methods: {
        getDevices(category) {
            if (this.$i18n.locale === "en") {
                switch (category) {
                    case "ios":
                        return ios
                    case "android":
                        return android
                    case "others":
                        return others
                    default:
                        return []
                }
            }
            if (this.$i18n.locale === "ja") {
                switch (category) {
                    case "ios":
                        return iosJa
                    case "android":
                        return androidJa
                    case "others":
                        return othersJa
                    default:
                        return []
                }
            }

            return []
        },
    },
    watch: {
        "$i18n.locale": {
            handler() {},
        },
    },
}
</script>

<style></style>
