<template>
    <div class="block-xl" :id="category.toLowerCase()">
        <h2 class="section-title">{{ category }}</h2>
        <div class="block-md">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-head">
                        <h3 class="panel-title">{{ $t("devices") }}</h3>
                    </div>
                </div>

                <div class="panel-body">
                    <div class="list-group">
                        <div class="list-group-item">
                            <div class="plans-row">
                                <div class="plans-row-body ts-quiet">
                                    <div class="plans-row-item name">
                                        <span>{{ $t("manufacturer") }}</span>
                                    </div>
                                    <div class="plans-row-item product">
                                        <span>{{ $t("product") }} / {{ $t("os_version") }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="list-group-item"
                            v-for="item in devices.devices"
                            :key="`${item.manufacturer}-${item.product}`"
                        >
                            <div class="plans-row">
                                <div class="plans-row-body ts-strong">
                                    <div class="plans-row-item name">
                                        <span>{{ item.manufacturer }}</span>
                                    </div>
                                    <div class="plans-row-item product">
                                        <span>{{ item.product }}</span>
                                        <span class="note ts-quiet" v-if="item.product_note">{{
                                            item.product_note
                                        }}</span>
                                        <span class="note ts-quiet"
                                            >{{ item.os
                                            }}<span v-if="item.os_note">
                                                {{ item.os_note }}</span
                                            ></span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    i18n: {
        messages: {
            en: {
                devices: "Devices",
                manufacturer: "By",
                product: "Product",
                os_version: "OS Version",
            },
            ja: {
                devices: "メーカーと端末名",
                manufacturer: "メーカー",
                product: "端末名",
                os_version: "OSバージョン",
            },
        },
    },

    props: {
        category: { type: String, required: true },
        devices: { required: true },
    },
    computed: {},
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0)
        },
    },
}
</script>

<style scoped>
.plans-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 479px) {
    .plans-row {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.plans-row-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

@media only screen and (max-width: 479px) {
    .plans-row-body {
        text-align: center;
    }
}

.plans-row-push {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100px;
    flex: 0 0 100px;
    margin-left: auto;
    text-align: right;
}

@media only screen and (max-width: 479px) {
    .plans-row-push {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        text-align: center;
    }

    .plans-row-push .button {
        display: block;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
        border-color: #eaebf1;
    }
}

@media only screen and (max-width: 479px) {
    .plans-row-push.hide-mobile {
        display: none;
    }
}

.plans-row-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
}

@media only screen and (max-width: 479px) {
    .plans-row-item {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 32%;
        flex: 0 0 32%;
    }

    .plans-row-item.left {
        text-align: left;
        margin-left: 2%;
    }

    .plans-row-item.right {
        text-align: right;
    }
}

.plans-row-item .note {
    display: block;
    font-size: 0.9em;
}
.plans-row-item.product {
    flex: 0 0 66%;
    text-align: left;
}
.panel .list-group-item {
    padding: 15px 4%;
}
.plans-row-item.name {
    flex: 0 0 20%;
}
@media (max-width: 400px) {
    .plans-row-item.name {
        text-align: right;
        padding-right: 15px;
        flex: 0 0 33%;
    }
    .panel .list-group-item {
        padding: 15px 0;
    }

    #android {
        margin-top: 4em;
    }
}
.ts-quiet {
    color: #555e72;
}
.list-group-item:not(:last-of-type) {
    border-bottom: 1px solid #eaebf1;
}
</style>
